.carousel-slider {
  height: 380px;
}

.control-dots {
  bottom: -20px !important;
}

.slider-wrapper {
  height: 360px !important;
}

@media screen and (max-width: 850px) {
  .carousel-slider {
    height: 340px;
  }
}

/* input {
  color-scheme: dark;
} */

@media screen and (max-width: 600px) {
  .carousel-slider {
    height: 300px;
  }

  .slider-wrapper {
    height: 300px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel.carousel-slider .control-arrow:active {
  background: none !important;
}