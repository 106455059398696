.wrapper {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.name {
  margin-top: 0.5rem;
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;

  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 100%;
  /* filter: brightness(80%); */
}

.event_info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  margin-top: 6px;
}

.event_info span {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}

.imageContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* Aspect ratio 16:9 - you can adjust this */
  overflow: hidden;
  border-radius: 8px;
  height: 0;
  /* This ensures that the padding-bottom sets the height */
}

.eventImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.carouselItem {
  display: flex;
  cursor: pointer;
  text-align: left !important;
  width: 80vw;
}

.desc {
  line-height: 25px;
  margin-top: 1rem;
  width: 70%;
}

.imageContainer {
  position: relative;
}

.flag {
  position: absolute;
  background: linear-gradient(to right, #a8284a, #da784b) border-box;
  top: 10px;
  /* Adjust as needed */
  left: 10px;
  /* Adjust as needed */
  width: 20px;
  /* Adjust as needed */
  height: 20px;
  /* Adjust as needed */
  z-index: 10;
}


.eventInfo {
  padding-left: 20px;
  width: 50% !important;
}

.event_title {
  text-transform: uppercase;
}

.desk {
  display: none;
}

.wrapp {
  width: 100%;
  padding-left: 5px;
}

.desc {
  display: none;
}

.time {
  color: #DA784B;
}

@media screen and (min-width: 768px) {
  .carouselItem {
    display: flex;
    cursor: pointer;
    text-align: left !important;
    width: 80vw;
    gap: 2rem;
  }

  .wrapp {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .eventImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 8px;
  }

  .desc {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 1rem;
    max-height: 340px;
  }

  .desc p {
    line-height: 22px;
  }
}

@media screen and (max-width: 1000px) {
  .event_title {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 850px) {
  .carouselItem {
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .eventInfo {
    text-align: center;
    width: 100% !important;
    position: absolute;
    bottom: 0;
  }

  .event_title {
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .carouselItem {
    width: 90vw;
  }

  .eventInfo {
    transform: translateY(200px);
  }
}